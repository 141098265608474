<template>
  <transition name="fade">
    <div v-show="isMaskShown" ref="maskRef" class="scroll-mask absolute w-full h-full top-0 left-0 pointer-events-none"></div>
  </transition>
</template>

<script setup lang="ts">
import { useEventListener } from '@vueuse/core'

const isMaskShown = ref(true)
const maskRef = ref<HTMLElement>()
const container = ref<HTMLElement>()

const handleScroll = (e: WheelEvent) => {
  const { scrollTop, scrollHeight, clientHeight } = e.target as HTMLElement
  isMaskShown.value = Math.abs(scrollHeight - scrollTop - clientHeight) > 1
}

onMounted(() => {
  container.value = maskRef.value?.parentNode as HTMLElement

  if (container.value) {
    isMaskShown.value = container.value.scrollHeight > container.value.clientHeight
    useEventListener(container.value, 'scroll', handleScroll)
  }
})
</script>

<style lang="scss" scoped>
.scroll-mask {
  background: linear-gradient(180deg, rgba(white, 0) 66.7%, rgba(white, 1) 100%);
}
</style>
